<template>
  <BidangUsahaForm mode="Ubah" module="Bidang Usaha"> </BidangUsahaForm>
</template>

<script>
import BidangUsahaForm from './form';

const BidangUsahaUpdate = {
  name: 'BidangUsahaUpdate',
  components: { BidangUsahaForm },
};

export default BidangUsahaUpdate;
</script>
